<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :elevation="hover ? 20 : 2"
      :class="{ info: checked, 'on-hover': hover }"
      @click="changeChecked"
      :ripple="!disabled"
    >
      <v-card-title class="subheading font-weight-bold">
        {{ material.name }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list dense color="transparent">
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              Formula:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              <span v-html="htmlFormula(material.formula)"></span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'CAS' }"
            >
              CAS:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'CAS' }"
            >
              {{ material.cas }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Phase' }"
            >
              Phase:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Phase' }"
            >
              {{ material.phase }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Molar Mass' }"
            >
              Molar Mass:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Molar Mass' }"
            >
              {{ molarMass }} kg/kmol
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              Enthalpy of Formation:
            </v-list-item-content>
            <v-list-item-content class="align-end">
              {{ hf0 }} kJ/mol
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <MaterialPopup
          :inputMaterial="material"
          :show="hover"
          :readonly="readonly"
          :iconColor="iconColor"
          @updateMaterial="updateMaterial"
        />
        <v-spacer v-if="!readonly"></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              v-if="!readonly"
              small
              depressed
              text
              color="transparent"
              @click="deleteMaterial"
            >
              <v-icon :color="hover ? iconColor : 'transparent'">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span> Delete </span>
        </v-tooltip>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex';
import MaterialPopup from './MaterialPopup';

export default {
  name: 'MaterialCard',
  props: {
    material: { type: Object, required: true },
    sortBy: { type: String },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disabledText: { type: String, default: '' },
    checked: { type: Boolean, required: true },
  },
  components: { MaterialPopup },
  data() {
    return {};
  },
  methods: {
    deleteMaterial() {
      this.$emit('deleteMaterial', this.material.publicId);
    },
    updateMaterial(updatedMaterial) {
      this.$emit('updateMaterial', updatedMaterial);
    },
    changeChecked() {
      this.disabled
        ? this.$emit('disabledAction', this.material)
        : this.$emit('changeChecked', this.material.publicId);
    },
  },
  computed: {
    ...mapGetters('materials', ['htmlFormula']),
    ...mapGetters(['iconColor']),
    molarMass() {
      return this.material.molarMass === null
        ? ''
        : (
            Math.round(this.material.molarMass * 1000 * 100) / 100
          ).toFixed(2);
    },
    hf0: {
      get: function() {
        return this.material.hf0 === null
          ? ''
          : (
              Math.round((this.material.hf0 / 1000) * 100) / 100
            ).toFixed(2);
      },
    },
  },
  mounted() {},
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.3s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
}

.sort-by-style {
  color: yellowgreen;
}
</style>
