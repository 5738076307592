<template>
  <div>
    <plotly v-bind="attr" :data="data" :layout="layout"> </plotly>
  </div>
</template>

<script>
export default {
  name: 'ElementalMassFractionsPieChart',
  components: {},
  props: {
    elementalMassFractions: { type: Object, required: true },
    title: { type: String, default: '' },
    showlegend: { type: Boolean, default: true },
  },
  data() {
    return {
      data: [
        {
          values: [],
          rotation: 0,
          textinfo: 'none',
          labels: [],
          hoverinfo: 'label+percent',
          hole: 0.5,
          type: 'pie',
        },
      ],
      attr: { displayModeBar: false },
      layout: {
        title: {
          text: this.title,
          font: {
            // family: 'Courier New, monospace',
            size: 18,
            // color: 'white',
          },
          xref: 'paper',
          x: 0.5,
        },
        showlegend: this.showlegend,
        legend: {
          font: {
            // color: 'white'
          },
          xref: 'paper',
          x: 0.65,
        },
        // autosize: true,
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 80,
          pad: 0,
        },
        hovermode: true,
        plot_bgcolor: 'rgba(0,0,0,0)',
        paper_bgcolor: 'rgba(0,0,0,0)',
        height: 250,
        // width: 500,
      },
    };
  },
  methods: {
    updateChart() {
      var layout = JSON.parse(JSON.stringify(this.layout));
      var labels = Object.keys(this.elementalMassFractions);
      var values = Object.values(this.elementalMassFractions).map(
        x => x * 100,
      );
      var data = [
        {
          values: values,
          rotation: 0,
          textinfo: 'label+percent',
          labels: labels,
          textposition: 'inside',
          // hoverinfo: 'label+percent',
          hovertemplate:
            'Element: %{label} <br>' +
            '%{value:.2f}  %mass<br>' +
            '<extra></extra>',
          hole: 0.0,
          type: 'pie',
          // showlegend: true,
          sort: false,
        },
      ];
      // assign local trace and layout objects back
      this.data = data;
      this.layout = layout;
    },
  },
  watch: {
    elementalMassFractions: {
      handler(newVal) {
        this.updateChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateChart();
  },
};
</script>

<style></style>
