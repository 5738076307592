<template>
  <div>
    <plotly v-bind="attr" :data="data" :layout="layout"> </plotly>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MaterialPropertyChart',
  components: {},
  props: {
    matprop: { type: Object, required: true },
    minimal: { type: Boolean, default: false },
  },
  data() {
    return {
      data: [
        {
          x: [],
          y: [],
          mode: 'lines+markers',
          type: 'scatter',
          marker: {
            color: '#A20000FF',
            size: 10,
            line: {
              // color: 'white',
              width: 0,
            },
          },
          line: {
            color: '#A20000FF',
            width: 4,
            shape: 'line',
          },
        },
      ],
      attr: { displayModeBar: !this.minimal },
      layout: {
        showlegend: !this.minimal,
        legend: {
          font: {
            // color: 'white'
          },
        },
        autosize: true,
        margin: this.minimal
          ? {
              l: 50,
              r: 25,
              t: 10,
              b: 50,
              pad: 0,
            }
          : {
              l: 75,
              r: 75,
              t: 30,
              b: 75,
              pad: 0,
            },
        title: {
          text: '',
          font: {
            // color: 'white',
          },
        },
        hovermode: !this.minimal,
        plot_bgcolor: 'rgba(0,0,0,0)',
        paper_bgcolor: 'rgba(0,0,0,0)',
        xaxis: {
          // color: 'white',
          // gridcolor: 'white',
          title: {
            text: '',
          },
          showline: true,
          showgrid: true,
        },
        yaxis: {
          // color: 'white',
          // gridcolor: 'white',
          title: '',
          showline: true,
          showgrid: true,
        },
      },
    };
  },
  methods: {
    updateChart() {
      var data = [];
      var layout = JSON.parse(JSON.stringify(this.layout));
      var trace;
      // console.log(this.matprop);
      if (this.matprop.approxMethod == 'constant') {
        trace = this.emptyTrace();
        trace.x = [this.matprop.TMin, this.matprop.TMax];
        trace.y = [this.matprop.yConst, this.matprop.yConst];
        trace.mode = 'lines';
        data.push(trace);
      } else if (this.matprop.approxMethod === 'piecewise linear') {
        trace = this.emptyTrace();
        trace.x = [...this.matprop.xLinear];
        trace.y = [...this.matprop.yLinear];
        trace.mode = 'lines+markers';
        trace.name = `${Math.min(
          ...this.matprop.xLinear,
        )} K - ${Math.max(...this.matprop.xLinear)} K`;
        data.push(trace);
      } else if (this.matprop.approxMethod === 'polynomial') {
        const n = 100;
        this.matprop.polynomials.forEach(poly => {
          var x = this.linspace(poly.TMin, poly.TMax, n);
          var y = [];
          for (var i = 0; i < n; i++) {
            y.push(0);
            for (var j = 0; j < poly.coefficients.length; j++) {
              var func = poly.functions[j];
              var T = x[i];
              if (func != null) {
                if (func === 'ln') {
                  T = Math.log(x[i]);
                } else if (func === 'log') {
                  T = Math.log10(x[i]);
                } else if (func === 'sin') {
                  T = Math.sin(x[i]);
                } else if (func === 'cos') {
                  T = Math.cos(x[i]);
                }
              }
              y[i] += poly.coefficients[j] * T ** poly.exponents[j];
            }
          }
          var trace = this.emptyTrace();
          trace.x = x;
          trace.y = y;
          trace.mode = 'lines';
          trace.name = `${poly.TMin} K - ${poly.TMax} K`;

          data.push(trace);
        });
      }

      var matpropDetail = this.matpropDetails.find(
        x => x.name === this.matprop.name,
      );
      layout.title.text = this.title;
      layout.xaxis.title.text = 'T [K]'; // '$T [K]$';
      layout.yaxis.title.text = `[${matpropDetail.unit}]`; //`$${matpropDetail.symbol} [${matpropDetail.unit}]$`;
      layout.showlegend = !this.minimal;
      // assign local trace and layout objects back
      this.data = data;
      this.layout = layout;
    },
    linspace(a, b, n) {
      if (typeof n === 'undefined')
        n = Math.max(Math.round(b - a) + 1, 1);
      if (n < 2) {
        return n === 1 ? [a] : [];
      }
      var i,
        ret = Array(n);
      n--;
      for (i = n; i >= 0; i--) {
        ret[i] = (i * b + (n - i) * a) / n;
      }
      return ret;
    },
    emptyTrace() {
      return {
        x: [],
        y: [],
        mode: 'lines+markers',
        type: 'scatter',
        marker: {
          color: '',
          size: 10,
          line: {
            // color: 'white',
            width: 0,
          },
        },
        line: {
          color: '',
          width: 4,
          shape: 'line',
        },
      };
    },
  },
  computed: {
    ...mapGetters('materials', ['matpropDetails']),
  },
  watch: {
    matprop: {
      handler(newVal) {
        this.updateChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateChart();
  },
};
</script>

<style></style>
