<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          small
          v-on="on"
          :class="{ 'show-btns': show }"
          color="transparent"
          @click="openDialog"
          depressed
          text
        >
          <v-icon :color="show ? iconColor : 'transparent'">
            {{ formIcon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span> {{ readonly ? 'Close' : 'Cancel' }} </span>
          </v-tooltip>
          <v-tooltip bottom v-if="!readonly">
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </template>
            <span>Reset</span>
          </v-tooltip>
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="!readonly">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="updatePolynomial"
                v-on="on"
              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </template>
            <span>Save Changes</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form class="px-3" ref="form">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-numeric-text-field-unit
                    v-model="polynomial.TMin"
                    :settings="settings.temperature"
                    :readonly="readonly"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-numeric-text-field-unit
                    v-model="polynomial.TMax"
                    :settings="settings.temperature"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>
              <hr />
              <v-row>
                <v-col cols="12">
                  <v-slider
                    label="Number of Coefficients"
                    v-model="numberOfCoefficients"
                    class="align-center"
                    max="10"
                    min="1"
                    :readonly="readonly"
                  >
                    <template v-slot:append>
                      <v-numeric-text-field-unit
                        v-model="numberOfCoefficients"
                        :settings="settings.numberOfCoefficients"
                        hide-details
                        single-line
                        style="width: 60px"
                        type="number"
                        max="10"
                        min="1"
                      />
                    </template>
                  </v-slider>
                </v-col>
              </v-row>
              <hr />
              <v-row
                v-for="(coeff, index) in polynomial.coefficients"
                :key="index"
              >
                <v-col cols="12" sm="6" md="4">
                  <v-numeric-text-field-unit
                    v-model="polynomial.coefficients[index]"
                    :settings="settings.coefficient"
                    :readonly="readonly"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-numeric-text-field-unit
                    v-model="polynomial.exponents[index]"
                    :settings="settings.exponent"
                    :readonly="readonly"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    label="Function"
                    :items="functions"
                    v-model="polynomial.functions[index]"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>
              <hr />
              <vue-mathjax :formula="formula"></vue-mathjax>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import UnitConverter from '@/plugins/unitconverter';

export default {
  name: 'PolynomialPopup',
  props: {
    inputPolynomial: { type: Object, required: true },
    symbol: { type: String, required: true },
    show: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    iconColor: { type: String, default: 'white' },
  },
  components: {},
  data() {
    return {
      dialog: false,
      functions: [null, 'ln', 'log', 'sin', 'cos'],
      polynomial: JSON.parse(JSON.stringify(this.inputPolynomial)),
      settings: {
        temperature: {
          label: 'Temperature',
          type: UnitConverter.types.TEMPERATURE,
          min: 0,
          max: null,
          baseUnit: 'K',
          unit: 'K',
          precision: 2,
          units: ['K', '°C', '°F'],
          precisions: [2, 2, 2],
        },
        numberOfCoefficients: {
          label: '',
          type: UnitConverter.types.UNITLESS,
          min: 1,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 0,
          units: [''],
          precisions: [0],
        },
        coefficient: {
          label: 'Coefficient',
          type: UnitConverter.types.UNITLESS,
          min: null,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 15,
          units: [''],
          precisions: [15],
        },
        exponent: {
          label: 'Exponent',
          type: UnitConverter.types.UNITLESS,
          min: null,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 2,
          units: [''],
          precisions: [2],
        },
      },
    };
  },
  methods: {
    updatePolynomial() {
      if (this.$refs.form.validate()) {
        this.$emit('updatePolynomial', this.polynomial);
        this.close();
      }
    },
    close() {
      this.reset();
      this.dialog = false;
    },
    reset() {
      (this.polynomial = JSON.parse(
        JSON.stringify(this.inputPolynomial),
      )),
        this.$refs.form.resetValidation();
    },
    openDialog(e) {
      e.cancelBubble = true;
      this.dialog = true;
    },
  },
  computed: {
    formTitle() {
      return 'Edit Polynomial';
    },
    formIcon() {
      if (this.readonly) {
        return 'mdi-information';
      } else {
        if (this.polynomial.id === -1) {
          return 'mdi-flask-empty-plus';
        } else {
          return 'mdi-pencil';
        }
      }
    },
    tooltipText() {
      if (this.readonly) {
        return 'Details';
      } else {
        if (this.polynomial.id === -1) {
          return 'New';
        } else {
          return 'Edit';
        }
      }
    },
    numberOfCoefficients: {
      get: function() {
        return this.polynomial.exponents.length;
      },
      set: function(newValue) {
        do {
          if (newValue > this.numberOfCoefficients) {
            this.polynomial.exponents.push(0);
            this.polynomial.coefficients.push(0);
            this.polynomial.functions.push(null);
          } else {
            this.polynomial.exponents.pop();
            this.polynomial.coefficients.pop();
            this.polynomial.functions.pop();
          }
        } while (this.numberOfCoefficients !== newValue);
      },
    },
    formula() {
      const precision = 4;

      var formula = `$$ ${this.symbol} = `;
      for (var i = 0; i < this.polynomial.coefficients.length; i++) {
        var coeff = this.polynomial.coefficients[i];
        if (coeff == 0) continue;
        var expo = this.polynomial.exponents[i];
        var func = this.polynomial.functions[i];

        const numInSciNot = {};
        [numInSciNot.coefficient, numInSciNot.exponent] = coeff
          .toExponential(precision)
          .split('e')
          .map(item => Number(item));

        var coeffAsString;
        if (numInSciNot.exponent > 5 || numInSciNot.exponent < -3) {
          coeffAsString = `${numInSciNot.coefficient} \\cdot 10^{${numInSciNot.exponent}}`;
        } else {
          coeffAsString = String(_.round(coeff, precision));
        }

        formula +=
          (coeff !== 1 && expo !== 0) || expo === 0
            ? coeffAsString
            : '';

        if (func !== null && expo !== 0) {
          formula += '\\cdot \\' + func + '(T)';
        } else {
          formula += expo != 0 ? '\\cdot T' : '';
        }
        formula += expo != 0 && expo != 1 ? `^{${String(expo)}}` : '';
        if (i < this.polynomial.coefficients.length - 1) {
          if (this.polynomial.coefficients[i + 1] > 0) {
            formula += '+';
          }
        }
      }
      if (formula == `$$ ${this.symbol} = `) formula += String(0);
      formula += ' $$';
      return formula;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style scoped></style>
