<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          small
          v-on="on"
          :class="buttonClass"
          @click="openDialog"
          depressed
          text
        >
          <v-icon :color="show ? iconColor : 'transparent'">
            {{ formIcon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ formTitle }}</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> {{ readonly ? 'Close' : 'Cancel' }} </span>
          </v-tooltip>
          <v-tooltip bottom v-if="!readonly">
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon> mdi-backup-restore </v-icon>
              </v-btn>
            </template>
            <span> Reset </span>
          </v-tooltip>
          <ElementalAnalysisPopup
            v-if="
              material != null &&
                material.publicId === -1 &&
                !readonly
            "
            :inputMaterial="material"
            @createMaterialFromElementalMassFractions="
              createMaterialFromElementalMassFractions
            "
          />
          <v-toolbar-title> {{ formTitle }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="!readonly">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="updateMaterial"
                v-on="on"
              >
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> Save Changes </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form class="px-3" ref="form">
            <v-expansion-panels v-model="panels" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>General</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        label="Name"
                        v-model="material.name"
                        :rules="[requiredRule]"
                        :readonly="readonly"
                      />
                      <v-text-field
                        label="CAS Number"
                        v-model="material.cas"
                        :rules="[requiredRule]"
                        :readonly="readonly"
                      />
                      <v-select
                        v-model="material.phase"
                        :items="phases"
                        :rules="[
                          v => (v && !!v) || 'Item is required',
                        ]"
                        label="Phase"
                        required
                        :readonly="readonly"
                      />
                      <!-- <v-numeric-text-field-unit
                        v-show="material.phase === 'g'"
                        v-model="material.ljSigma"
                        :settings="settings.ljSigma"
                        :readonly="readonly"
                      /> -->
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        label="Formula"
                        v-model="material.formula"
                        :rules="[requiredRule]"
                        :readonly="readonly"
                        @change="createMaterial"
                      />
                      <v-numeric-text-field-unit
                        v-model="material.molarMass"
                        :settings="settings.molarMass"
                        :readonly="autoMolarMass || readonly"
                      >
                        <v-icon
                          slot="prepend"
                          @click="changeAutoMolarMass"
                        >
                          {{ autoMolarMassIcon }}
                        </v-icon>
                      </v-numeric-text-field-unit>
                      <v-numeric-text-field-unit
                        v-model="material.hf0"
                        :settings="settings.hf0"
                        :readonly="readonly"
                        @change="createMaterial"
                      >
                        <v-tooltip bottom slot="prepend">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              @click="estimateHf0WithBoieFormula"
                            >
                              mdi-bullseye
                            </v-icon>
                          </template>
                          <span> Estimate with Boie Formula</span>
                        </v-tooltip>
                      </v-numeric-text-field-unit>
                      <!-- <v-numeric-text-field-unit
                        v-if="material.phase === 'g'"
                        v-model="material.ljEpsK"
                        :settings="settings.ljEpsK"
                        :readonly="readonly"
                      /> -->
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <ElementalMassFractionsPieChart
                        :elementalMassFractions="
                          material.elementalMassFractions
                        "
                        title="Elemental Mass Fractions"
                        v-show="material.formula != ''"
                        ref="pieChart"
                      />
                    </v-col>
                  </v-row>
                  <v-row style="mx-4">
                    <v-col cols="12" sm="6" md="4">
                      <v-numeric-text-field-unit
                        v-model="material.rho"
                        :settings="settings.rho"
                        :readonly="autoDensity || readonly"
                        :rules="[requiredRule]"
                      >
                        <v-icon
                          v-show="material.phase === 'g'"
                          slot="prepend"
                          @click="changeAutoDensity"
                        >
                          {{ autoDensityIcon }}
                        </v-icon>
                      </v-numeric-text-field-unit>
                      <v-numeric-text-field-unit
                        v-model="material.LHVBoie"
                        :settings="settings.LHVBoie"
                        readonly
                      />
                      <v-numeric-text-field-unit
                        v-model="material.LHVNist"
                        :settings="settings.LHVNist"
                        readonly
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-numeric-text-field-unit
                        v-model="material.molarVolume"
                        :settings="settings.molarVolume"
                        :readonly="autoMolarVolume || readonly"
                      >
                        <v-icon
                          slot="prepend"
                          @click="changeAutoMolarVolume"
                        >
                          {{ autoMolarVolumeIcon }}
                        </v-icon>
                      </v-numeric-text-field-unit>
                      <v-numeric-text-field-unit
                        v-model="material.CHRatio"
                        :settings="settings.CHRatio"
                        readonly
                      />
                      <v-numeric-text-field-unit
                        v-model="material.O2Demand"
                        :settings="settings.O2Demand"
                        readonly
                      >
                        <template slot="label">
                          <span v-html="htmlFormula('O2')"></span>
                          Demand
                        </template>
                        <template slot="append">
                          <span
                            >kg<sub v-html="htmlFormula('O2')"></sub>
                            /kg<sub>fuel</sub>
                          </span>
                        </template>
                      </v-numeric-text-field-unit>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Material Properties</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row>
                    <v-col
                      v-for="matprop in material.matprops"
                      :key="matprop.name"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="3"
                    >
                      <MaterialPropertyCard
                        ref="matprops"
                        :inputMatprop="matprop"
                        :readonly="readonly"
                        :iconColor="matpropIconColor"
                        @updateMatprop="updateMatprop"
                        @deleteMatprop="deleteMatprop"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="!readonly">
                    <v-col cols="12" sm="6" md="4" lg="2">
                      <v-menu
                        v-model="value"
                        :open-on-hover="true"
                        :close-on-content-click="true"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn fab class="success" v-on="on">
                            <v-icon> mdi-plus </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="item in matpropDetailsForPhase"
                            :key="item.name"
                            @click="addMatprop(item.name)"
                            :disabled="
                              matpropAlreadyExists(item.name)
                            "
                          >
                            <v-list-item-title>
                              {{ item.fullName }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import MaterialPropertyCard from './MaterialPropertyCard';
import ElementalMassFractionsPieChart from '@/components/charts/ElementalMassFractionsPieChart';
import ElementalAnalysisPopup from '@/components/materials/ElementalAnalysisPopup';
import UnitConverter from '@/plugins/unitconverter';

export default {
  name: 'MaterialPopup',
  props: {
    inputMaterial: { type: Object, required: true },
    show: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    iconColor: { type: String, default: 'white' },
    buttonClass: { type: String, default: 'transparent' },
  },
  components: {
    MaterialPropertyCard,
    ElementalMassFractionsPieChart,
    ElementalAnalysisPopup,
  },
  data() {
    return {
      dialog: false,
      material: JSON.parse(JSON.stringify(this.inputMaterial)),
      nameRules: [v => (v && v.length >= 1) || 'Minimum length is 1'],
      requiredRule: value => !!value || 'Field is required',
      phases: ['g', 'l', 's'],
      settings: {
        ljSigma: {
          label: 'Lennard-Jones Characteristic Length',
          type: UnitConverter.types.LENGTH,
          min: 0,
          max: null,
          baseUnit: 'm',
          unit: 'Å',
          precision: 3,
          units: ['Å', 'm'],
          precisions: [3, 14],
        },
        ljEpsK: {
          label: 'Lennard-Jones Energy Parameter',
          type: UnitConverter.types.TEMPERATURE,
          min: 0,
          max: null,
          baseUnit: 'K',
          unit: 'K',
          precision: 3,
          units: ['K'],
          precisions: [3],
        },
        molarMass: {
          label: 'Molar mass',
          type: UnitConverter.types.MOLARMASS,
          min: 0,
          max: null,
          baseUnit: 'kg/mol',
          unit: 'kg/kmol',
          precision: 3,
          units: ['kg/kmol'],
          precisions: [3],
        },
        rho: {
          label: 'Density',
          type: UnitConverter.types.DENSITY,
          min: 0,
          max: null,
          baseUnit: 'kg/m³',
          unit: 'kg/m³',
          precision: 2,
          units: ['g/cm³', 'kg/m³'],
          precisions: [4, 2],
        },
        hf0: {
          label: 'Standard Enthalpy of formation',
          type: UnitConverter.types.ENTHALPY,
          min: null,
          max: null,
          baseUnit: 'J/mol',
          unit: 'kJ/mol',
          precision: 3,
          units: ['kJ/mol'],
          precisions: [3],
        },
        molarVolume: {
          label: 'Molar Volume',
          type: UnitConverter.types.MOLARVOLUME,
          min: 0,
          max: null,
          baseUnit: 'Nm³/mol',
          unit: 'Nm³/kmol',
          precision: 3,
          units: ['Nm³/kmol'],
          precisions: [3],
        },
        CHRatio: {
          label: 'C/H ratio',
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 3,
          units: [''],
          precisions: [3],
        },
        O2Demand: {
          label: 'O2 demand',
          type: UnitConverter.types.UNITLESS,
          min: null,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 3,
          units: [''],
          precisions: [3],
        },
        LHVBoie: {
          label: 'LHV (Boie)',
          type: UnitConverter.types.ENTHALPY,
          min: null,
          max: null,
          baseUnit: 'J/kg',
          unit: 'kJ/kg',
          precision: 0,
          units: [
            'kJ/kg',
            'MJ/kg',
            'kWh/kg',
            'kcal/kg',
            'kJ/Nm³',
            'MJ/Nm³',
            'kWh/Nm³',
            'kcal/Nm³',
          ],
          precisions: [0, 3, 2, 0, 0, 3, 2, 0],
        },
        LHVNist: {
          label: 'LHV (Nist)',
          type: UnitConverter.types.ENTHALPY,
          min: null,
          max: null,
          baseUnit: 'J/kg',
          unit: 'kJ/kg',
          precision: 0,
          units: [
            'kJ/kg',
            'MJ/kg',
            'kWh/kg',
            'kcal/kg',
            'kJ/Nm³',
            'MJ/Nm³',
            'kWh/Nm³',
            'kcal/Nm³',
          ],
          precisions: [0, 3, 2, 0, 0, 3, 2, 0],
        },
      },
      LHVUnits: [
        'kJ/kg',
        'kWh/kg',
        'kcal/kg',
        'kJ/Nm³',
        'kWh/Nm³',
        'kcal/Nm³',
      ],
      LHVUnit: 'kJ/kg',
      panels: [0, 1],
      matpropNames: ['c_p', 'h', 's', 'lam', 'p_sat'],
      autoMolarMass: true,
      autoMolarVolume: true,
      autoDensity: true,
      value: '',
    };
  },
  methods: {
    updateMaterial() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('misc/setLoading', true);
        if (this.material.publicId === -1) {
          this.$store
            .dispatch('materials/addMaterial', this.material)
            .then(response => {
              this.$store.dispatch('misc/setLoading', false);
              var snack = {
                text: response.data.msg,
                color: 'success',
              };
              this.$store.dispatch('misc/setSnack', snack);
              this.close();
            })
            .catch(error => {
              this.$store.dispatch('misc/setLoading', false);
              var snack = {
                text: error.response.data.msg,
                color: 'error',
              };
              this.$store.dispatch('misc/setSnack', snack);
              console.log(error);
            });
        } else {
          this.$store
            .dispatch('materials/updateMaterial', this.material)
            .then(response => {
              this.$store.dispatch('misc/setLoading', false);
              var snack = {
                text: response.data.msg,
                color: 'success',
              };
              this.$store.dispatch('misc/setSnack', snack);
              this.close();
            })
            .catch(error => {
              this.$store.dispatch('misc/setLoading', false);
              var snack = {
                text: error,
                color: 'error',
              };
              this.$store.dispatch('misc/setSnack', snack);
              console.log(error);
            });
        }
      } else {
        var snack = {
          text:
            'Some inputs are missing or not correct, please check!',
          color: 'error',
        };
        this.$store.dispatch('misc/setSnack', snack);
      }
    },
    addMatprop(matpropName) {
      var newMatprop = this.emptyMatprop();
      newMatprop.name = matpropName;
      this.material.matprops.push(newMatprop);
    },
    updateMatprop(updatedMatprop) {
      const index = this.material.matprops.findIndex(
        matprop => matprop.name === updatedMatprop.name,
      );
      Object.assign(this.material.matprops[index], updatedMatprop);
    },
    deleteMatprop(matpropToDelete) {
      this.material.matprops = this.material.matprops.filter(
        matprop => matprop.name != matpropToDelete.name,
      );
    },
    matpropAlreadyExists(matpropName) {
      return this.material.matprops.some(
        matprop => matprop.name === matpropName,
      );
    },
    createMaterialFromElementalMassFractions(material) {
      this.material = material;
    },
    close() {
      this.reset();
      this.dialog = false;
    },
    reset() {
      this.material = JSON.parse(JSON.stringify(this.inputMaterial));
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    openDialog(e) {
      e.cancelBubble = true;
      this.reset();
      this.dialog = true;
    },
    createMaterial() {
      this.$store
        .dispatch('materials/createMaterial', {
          material: this.material,
          calculateMolarMass: this.autoMolarMass,
          calculateMolarVolume: this.autoMolarVolume,
          calculateDensity: this.autoDensity,
        })
        .then(response => {
          this.material = response.data.material;
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeAutoMolarMass() {
      this.autoMolarMass = !this.autoMolarMass;
      if (this.autoMolarMass) {
        this.createMaterial();
      }
    },
    changeAutoMolarVolume() {
      this.autoMolarVolume = !this.autoMolarVolume;
      if (this.autoMolarVolume) {
        this.createMaterial();
      }
    },
    changeAutoDensity() {
      this.autoDensity = !this.autoDensity;
      if (this.autoDensity) {
        this.createMaterial();
      }
    },
    estimateHf0WithBoieFormula() {
      this.$store
        .dispatch(
          'materials/estimateHf0WithBoieFormula',
          this.material.formula,
        )
        .then(response => {
          this.material.hf0 = response.data;
          this.createMaterial();
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters('materials', [
      'matpropDetails',
      'emptyMatprop',
      'htmlFormula',
    ]),
    ...mapGetters({ matpropIconColor: 'iconColor' }),
    matpropDetailsForPhase() {
      return this.matpropDetails.filter(detail =>
        detail.phases.includes(this.material.phase),
      );
    },
    formTitle() {
      if (this.readonly) {
        return 'Info';
      } else {
        if (this.material.publicId === -1) {
          return 'New';
        } else {
          return 'Edit';
        }
      }
    },
    formIcon() {
      if (this.readonly) {
        return 'mdi-information';
      } else {
        if (this.material.publicId === -1) {
          return 'mdi-flask-empty-plus';
        } else {
          return 'mdi-pencil';
        }
      }
    },
    autoMolarMassIcon() {
      return this.autoMolarMass ? 'mdi-lock' : 'mdi-lock-open';
    },
    autoMolarVolumeIcon() {
      return this.autoMolarVolume ? 'mdi-lock' : 'mdi-lock-open';
    },
    autoDensityIcon() {
      return this.autoDensity ? 'mdi-lock' : 'mdi-lock-open';
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    'material.phase'(val) {
      if (this.autoDensity) {
        if (val !== 'g') {
          this.autoDensity = false;
        }
      }
    },
  },
  mounted() {
    if (this.material.phase !== 'g') {
      this.autoDensity = false;
    }
  },
};
</script>

<style scoped></style>
