<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :elevation="hover ? 20 : 2"
      :class="{ 'on-hover': hover }"
    >
      <v-card-title class="subheading font-weight-bold">
        {{ title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list>
          <v-list-item>
            Temperature Range: {{ inputPolynomial.TMin }} K -
            {{ inputPolynomial.TMax }} K
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <PolynomialPopup
          :inputPolynomial="polynomial"
          :symbol="symbol"
          :show="hover"
          :readonly="readonly"
          :iconColor="iconColor"
          @updatePolynomial="updatePolynomial"
        />
        <v-spacer v-if="!readonly"></v-spacer>
        <v-tooltip bottom v-if="!readonly">
          <template v-slot:activator="{ on }">
            <v-btn
              small
              v-on="on"
              color="transparent"
              @click="deletePolynomial"
              depressed
              text
            >
              <v-icon :color="hover ? iconColor : 'transparent'">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span> Delete </span>
        </v-tooltip>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import PolynomialPopup from './PolynomialPopup';

export default {
  name: 'PolynomialCard',
  props: {
    inputPolynomial: { type: Object, required: true },
    symbol: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    iconColor: { type: String, default: 'white' },
  },
  components: { PolynomialPopup },
  data() {
    return {
      polynomial: this.inputPolynomial,
    };
  },
  methods: {
    deletePolynomial() {
      this.$emit('deletePolynomial', this.polynomial.publicId);
    },
    updatePolynomial(updatedPolynomial) {
      this.$emit('updatePolynomial', updatedPolynomial);
    },
  },
  computed: {
    title() {
      return `Polynomial for ${this.polynomial.TMin} K - ${this.polynomial.TMax} K`;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.3s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
}
</style>
