<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          small
          v-on="on"
          color="transparent"
          @click="openDialog"
          depressed
          text
        >
          <v-icon :color="show ? iconColor : 'transparent'">
            {{ formIcon }}
          </v-icon>
        </v-btn>
      </template>
      <span> {{ tooltipText }} </span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> {{ readonly ? 'Close' : 'Cancel' }} </span>
          </v-tooltip>
          <v-tooltip bottom v-if="!readonly">
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon> mdi-backup-restore </v-icon>
              </v-btn>
            </template>
            <span> Reset </span>
          </v-tooltip>
          <v-toolbar-title> {{ formTitle }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="!readonly">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="updateMatprop"
                v-on="on"
              >
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> Save Changes </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form class="px-3" ref="form">
            <v-container fluid>
              <h4>Approximation Method</h4>
              <v-radio-group v-model="approxMethod" row>
                <v-radio
                  v-for="approxMethod in approxMethods"
                  :key="approxMethod"
                  :label="approxMethod"
                  :value="approxMethod"
                ></v-radio>
              </v-radio-group>
              <hr />
              <h4>Inputs</h4>
              <template v-if="matprop.approxMethod === 'constant'">
                <v-row>
                  <v-col cols="5" xs="2">
                    <v-numeric-text-field-unit
                      v-model="matprop.yConst"
                      :settings="settings.constant"
                    />
                  </v-col>
                </v-row>
                <hr />
                <vue-mathjax :formula="calcFormula(-1)"></vue-mathjax>
              </template>
              <template
                v-else-if="
                  matprop.approxMethod === 'piecewise linear'
                "
              >
                <v-container fluid>
                  <v-row justify="center">
                    <v-col cols="12" xs="12" sm="6" lg="2">
                      <v-textarea
                        name="xLinear"
                        filled
                        label="Temperatures [K]"
                        auto-grow
                        v-model="xLinear"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" lg="2">
                      <v-textarea
                        name="yLinear"
                        filled
                        :label="
                          `${matpropDetail.symbol} [${matpropDetail.unit}]`
                        "
                        auto-grow
                        v-model="yLinear"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
                <hr />
                <vue-mathjax :formula="calcFormula(-1)"></vue-mathjax>
              </template>
              <template
                v-else-if="matprop.approxMethod === 'polynomial'"
              >
                <v-row>
                  <v-col
                    v-for="(polynomial, index) in sortedPolynomials"
                    :key="index"
                    cols="12"
                    sm="12"
                    md="4"
                    lg="3"
                  >
                    <PolynomialCard
                      :inputPolynomial="polynomial"
                      :symbol="symbol"
                      :readonly="readonly"
                      :iconColor="iconColor"
                      @updatePolynomial="updatePolynomial"
                      @deletePolynomial="deletePolynomial"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="!readonly">
                  <v-col cols="12" sm="6" md="4" lg="2">
                    <v-btn fab class="success" @click="addPolynomial">
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <hr />
              <v-flex>
                <MaterialPropertyChart
                  :matprop="matprop"
                  :minimal="false"
                  ref="chart"
                />
              </v-flex>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PolynomialCard from './PolynomialCard';
import UnitConverter from '@/plugins/unitconverter';
import MaterialPropertyChart from '@/components/charts/MaterialPropertyChart';

export default {
  name: 'MaterialPropertyPopup',
  props: {
    inputMatprop: { type: Object, required: true },
    matpropDetail: { type: Object, required: true },
    show: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    iconColor: { typ: String, default: 'white' },
  },
  components: { PolynomialCard, MaterialPropertyChart },
  data() {
    return {
      dialog: false,
      matprop: JSON.parse(JSON.stringify(this.inputMatprop)),
      approxMethods: ['constant', 'piecewise linear', 'polynomial'],
      settings: {
        // TODO: show correct unit, maybe hand over settings as property?
        constant: {
          label: 'Constant Value',
          type: UnitConverter.types.UNITLESS,
          baseUnit: this.matpropDetail.unit,
          unit: this.matpropDetail.unit,
          precision: 3,
          units: [this.matpropDetail.unit],
          precisions: [3],
        },
      },
    };
  },
  methods: {
    toArray(stringToSplit) {
      var splitString = stringToSplit.split('\n');
      var result = splitString.map(x => parseFloat(x));
      return result;
    },
    updateTMinAndTMax() {
      // adapt TMin and TMax of matprop
      if (this.matprop.approxMethod == 'polynomial') {
        this.matprop.TMin = Math.min(
          ...this.matprop.polynomials.map(o => o.TMin),
        );
        this.matprop.TMax = Math.max(
          ...this.matprop.polynomials.map(o => o.TMax),
        );
      } else if (this.matprop.approxMethod == 'piecewise linear') {
        this.matprop.TMin = Math.min(...this.matprop.xLinear);
        this.matprop.TMax = Math.max(...this.matprop.xLinear);
      } else {
        this.matprop.TMin = 223.15;
        this.matprop.TMax = 2273.15;
      }
    },
    updateMatprop() {
      if (this.$refs.form.validate()) {
        this.updateTMinAndTMax();
        this.$emit('updateMatprop', this.matprop);
        this.close();
      }
    },
    addPolynomial() {
      this.matprop.polynomials.push(this.emptyPolynomial());
    },
    updatePolynomial(updatedPolynomial) {
      const index = this.matprop.polynomials.findIndex(
        poly => poly.id === updatedPolynomial.id,
      );
      Object.assign(
        this.matprop.polynomials[index],
        updatedPolynomial,
      );
      this.updateTMinAndTMax();
    },
    deletePolynomial(publicId) {
      var index = this.matprop.polynomials.findIndex(
        poly => poly.publicId == publicId,
      );
      if (index !== -1) {
        this.matprop.polynomials.splice(index, 1);
      }
    },
    calcFormula(i) {
      var formula;
      var name = this.matprop.name;
      if (this.matprop.approxMethod == 'constant') {
        formula = `$$ ${name} = const. $$`;
      } else if (this.matprop.approxMethod == 'piecewise linear') {
        if (name.includes('_')) {
          var name_adjusted = name.replace('_', '_{') + ',';
          formula = `$$ ${name}(T) = {${name_adjusted} i + 1}  - ${name_adjusted} i} \\over T_{i+1} - T_i} * T + ${name_adjusted}i} $$`;
        } else {
          formula = `$$ ${name}(T) = {${name}_{i + 1}  - ${name}_{i} \\over T_{i+1} - T_i} * T + ${name}_{i} $$`;
        }
      } else {
        formula = `$$ ${name}(T) = a_0 + a_1 T + a_2 T^2 + a_3 T^3 $$`;
        this.matprop.polynomials[i - 1].formula = formula;
      }
      return formula;
    },
    close() {
      this.reset();
      this.dialog = false;
    },
    reset() {
      this.matprop = JSON.parse(JSON.stringify(this.inputMatprop));
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    openDialog(e) {
      e.cancelBubble = true;
      this.dialog = true;
    },
  },
  computed: {
    ...mapGetters('materials', ['matpropDetails', 'emptyPolynomial']),
    formTitle() {
      return this.matpropDetail.fullName;
    },
    formIcon() {
      if (this.readonly) {
        return 'mdi-information';
      } else {
        if (this.matprop.publicId === -1) {
          return 'mdi-flask-empty-plus';
        } else {
          return 'mdi-pencil';
        }
      }
    },
    tooltipText() {
      if (this.readonly) {
        return 'Details';
      } else {
        if (this.matprop.publicId === -1) {
          return 'New';
        } else {
          return 'Edit';
        }
      }
    },
    sortedPolynomials() {
      // sorted by TMin
      return [...this.matprop.polynomials].sort((a, b) => {
        b.TMin - a.TMin;
      });
      // return _.orderBy(this.polynomials, 'TMin');
    },
    symbol() {
      return this.matpropDetails.find(
        x => x.name === this.matprop.name,
      ).symbol;
    },
    xLinear: {
      get: function() {
        return this.matprop.xLinear.join('\n');
      },
      set: function(newValue) {
        this.matprop.xLinear = this.toArray(newValue);
      },
    },
    yLinear: {
      get: function() {
        return this.matprop.yLinear.join('\n');
      },
      set: function(newValue) {
        this.matprop.yLinear = this.toArray(newValue);
      },
    },
    approxMethod: {
      get: function() {
        return this.matprop.approxMethod;
      },
      set: function(v) {
        this.matprop.approxMethod = v;
        this.updateTMinAndTMax();
      },
    },
  },
  watch: {
    dialog(val) {
      if (val) this.reset();
      val || this.close();
    },
  },
};
</script>

<style scoped></style>
