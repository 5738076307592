<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" @click="dialog = true">
          <v-icon> mdi-lightning-bolt </v-icon>
        </v-btn>
      </template>
      <span>Create Material from Elemental Analysis</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> Cancel </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon> mdi-backup-restore </v-icon>
              </v-btn>
            </template>
            <span> Reset </span>
          </v-tooltip>
          <v-toolbar-title>
            Create from Elemental Analysis
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="createMaterial"
                v-on="on"
              >
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> Create Material </span>
          </v-tooltip>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-form class="px-3" ref="form" :disabled="loading">
              <v-container fluid>
                <v-row style="mx-4">
                  <v-col cols="12" sm="6" md="4">
                    <v-card>
                      <v-card-title>
                        Elemental Analysis (daf)</v-card-title
                      >
                      <v-card-text>
                        <v-numeric-text-field-unit
                          v-for="(value,
                          elem) in elementalMassFractions"
                          :key="elem"
                          v-model="elementalMassFractions[elem]"
                          :label="elem"
                          :settings="settings.elementalMassFraction"
                        />
                        <v-numeric-text-field-unit
                          v-model="sumOfElementalMassFractions"
                          label="Sum"
                          :settings="settings.elementalMassFraction"
                          disabled
                          :rules="not100PercentRule"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-card>
                      <v-card-title> </v-card-title>
                      <v-card-text>
                        <v-select
                          v-model="phase"
                          :items="phases"
                          label="Phase"
                          :rules="requiredRule"
                        />
                        <v-switch
                          v-model="hf0Given"
                          :label="settings.hf0.label"
                        />
                        <v-switch
                          v-show="phase === 's'"
                          v-model="hMeltGiven"
                          :label="settings.hMelt.label"
                        />
                        <v-switch
                          v-show="phase === 's' || phase === 'l'"
                          v-model="hEvapGiven"
                          :label="settings.hEvap.label"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-card>
                      <v-card-title> </v-card-title>
                      <v-card-text>
                        <v-numeric-text-field-unit
                          v-if="hf0Given"
                          v-model="hf0"
                          :settings="settings.hf0"
                          :rules="requiredRule"
                        />
                        <v-numeric-text-field-unit
                          v-if="hMeltGiven"
                          v-model="hMelt"
                          :settings="settings.hMelt"
                          :rules="requiredRule"
                        />
                        <v-numeric-text-field-unit
                          v-if="hEvapGiven"
                          v-model="hEvap"
                          :settings="settings.hEvap"
                          :rules="requiredRule"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
        <v-overlay
          :value="loading"
          :absolute="true"
          :opacity="0.9"
          :z-index="5"
        >
          <v-progress-circular
            indeterminate
            color="info"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import UnitConverter from '@/plugins/unitconverter';

export default {
  name: 'ElementalAnalysisPopup',
  components: {},
  props: {
    inputMaterial: { type: Object, required: true },
  },
  data() {
    return {
      material: JSON.parse(JSON.stringify(this.inputMaterial)),
      loading: false,
      dialog: false,
      requiredRule: [v => !!v || 'Item is required'],
      not100PercentRule: [
        v =>
          _.round(this.sumOfElementalMassFractions, 6) == 1.0 ||
          `Has to sum up to 100%. Current Difference: ${_.round(
            this.sumOfElementalMassFractions * 100 - 100,
            6,
          )} %`,
      ],
      phases: ['g', 'l', 's'],
      elementalMassFractions: {},
      hf0Given: false,
      hMeltGiven: false,
      hEvapGiven: false,
      phase: this.inputMaterial.phase,
      hf0: null,
      hMelt: null,
      hEvap: null,
      settings: {
        elementalMassFraction: {
          label: 'Dummy',
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: 1,
          baseUnit: '',
          unit: '%mass',
          precision: 3,
          units: ['%mass'],
          precisions: [3],
        },
        hf0: {
          label: 'Standard Enthalpy of Formation',
          type: UnitConverter.types.ENTHALPY,
          min: null,
          max: null,
          baseUnit: 'J/mol',
          unit: 'kJ/mol',
          precision: 3,
          units: ['kJ/mol'],
          precisions: [3],
        },
        hMelt: {
          label: 'Standard Enthalpy of Melting',
          type: UnitConverter.types.ENTHALPY,
          min: null,
          max: null,
          baseUnit: 'J/mol',
          unit: 'kJ/mol',
          precision: 3,
          units: ['kJ/mol'],
          precisions: [3],
        },
        hEvap: {
          label: 'Standard Enthalpy of Evaporation',
          type: UnitConverter.types.ENTHALPY,
          min: null,
          max: null,
          baseUnit: 'J/mol',
          unit: 'kJ/mol',
          precision: 3,
          units: ['kJ/mol'],
          precisions: [3],
        },
      },
    };
  },
  methods: {
    createMaterial() {
      this.validateForm()
        .then(response => {
          this.loading = true;
          this.material.phase = this.phase;
          this.material.hf0 = this.hf0Given ? this.hf0 : null;
          var hMelt = this.hMeltGiven ? this.hMelt : null;
          var hEvap = this.hEvapGiven ? this.hEvap : null;
          this.$store
            .dispatch(
              'materials/createMaterialFromElementalMassFractions',
              {
                elementalMassFractions: this.elementalMassFractions,
                material: this.material,
                hMelt,
                hEvap,
              },
            )
            .then(response => {
              var material = response.data.material;
              material.publicId = this.material.publicId;
              this.$emit(
                'createMaterialFromElementalMassFractions',
                material,
              );
              this.$store.dispatch('misc/setSnack', {
                text: response.data.msg,
                color: 'success',
              });
              this.close();
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(error => {
          this.$store.dispatch('misc/setSnack', {
            text: error.response.data.msg,
            color: 'error',
          });
          return;
        });
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        if (_.round(this.sumOfElementalMassFractions, 6) != 1.0) {
          reject({
            response: {
              data: {
                msg:
                  'Elemental Mass fractions have to sum up to 100 %. Please check your inputs.',
              },
            },
          });
        } else if (!this.$refs.form.validate()) {
          reject({
            response: {
              data: {
                msg:
                  'Some inputs are missing or not correct, please check!',
              },
            },
          });
        } else {
          resolve({ data: { msg: 'Validation successful!' } });
        }
      });
    },
    close() {
      // this.reset();
      this.dialog = false;
    },
    reset() {
      this.elementalMassFractions = this.emptyElementalAnalysis();
      this.phase = this.material.phase;
      this.hf0 = null;
      this.hMelt = null;
      this.hEvap = null;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
  },
  computed: {
    ...mapGetters('materials', ['emptyElementalAnalysis']),
    sumOfElementalMassFractions() {
      return Object.keys(this.elementalMassFractions).reduce(
        (sum, key) =>
          sum + parseFloat(this.elementalMassFractions[key] || 0),
        0,
      );
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
      if (val) {
        this.phase = this.material.phase;
      }
    },
    // hf0Given(val) {
    //   console.log(val);
    //   console.log(this.hf0Given);
    //   console.log(this.material.hf0);
    //   if (!this.hf0Given) {
    //     this.material.hf0 = null;
    //   }
    //   console.log(this.material.hf0);
    // },
    elementalMassFractions: {
      handler(newVal) {
        Object.keys(newVal).forEach(element => {
          if (newVal[element] === '') {
            newVal[element] = 0.0;
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.elementalMassFractions = this.emptyElementalAnalysis();
  },
};
</script>

<style></style>
