<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :elevation="hover ? 20 : 2"
      :class="{ 'on-hover': hover }"
    >
      <v-card-title class="subheading font-weight-bold">
        {{ fullName }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list>
          <v-list-item>
            Approximation Method: {{ inputMatprop.approxMethod }}
          </v-list-item>
          <v-list-item v-if="inputMatprop.approxMethod != 'constant'">
            Temperature Range: {{ inputMatprop.TMin }} K -
            {{ inputMatprop.TMax }} K
          </v-list-item>
        </v-list>
        <v-container fluid>
          <MaterialPropertyChart
            :matprop="inputMatprop"
            :minimal="true"
            ref="chart"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <MaterialPropertyPopup
          ref="popup"
          :inputMatprop="inputMatprop"
          :matpropDetail="matpropDetail"
          :show="hover"
          :readonly="readonly"
          :iconColor="iconColor"
          @updateMatprop="updateMatprop"
        />
        <v-spacer v-if="!readonly"></v-spacer>
        <v-tooltip bottom v-if="!readonly">
          <template v-slot:activator="{ on }">
            <v-btn
              small
              v-on="on"
              :class="{ 'show-btns': hover }"
              color="transparent"
              @click="deleteMatprop"
              depressed
              text
            >
              <v-icon :color="hover ? iconColor : 'transparent'">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span> Delete </span>
        </v-tooltip>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex';
import MaterialPropertyPopup from './MaterialPropertyPopup';
import MaterialPropertyChart from '@/components/charts/MaterialPropertyChart';

export default {
  name: 'MaterialPropertyCard',
  props: {
    inputMatprop: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
    iconColor: { type: String, default: 'white' },
  },
  components: { MaterialPropertyPopup, MaterialPropertyChart },
  data() {
    return {
      // matprop: this.inputMatprop,
    };
  },
  methods: {
    deleteMatprop() {
      this.$emit('deleteMatprop', this.inputMatprop);
    },
    updateMatprop(updatedMatprop) {
      this.$emit('updateMatprop', updatedMatprop);
    },
    reset() {
      if (this.$refs.popup) {
        this.$refs.popup.reset();
      }
    },
  },
  computed: {
    ...mapGetters('materials', ['matpropDetails']),
    matpropDetail() {
      return this.matpropDetails.find(
        x => x.name === this.inputMatprop.name,
      );
    },
    fullName() {
      return this.matpropDetail.fullName;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.3s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
}
</style>
