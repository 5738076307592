<template>
  <v-card>
    <v-container fluid>
      <v-data-iterator
        ref="materialIterator"
        :items="materials"
        :items-per-page.sync="itemsPerPage"
        :page="page"
        :search="search"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
        hide-default-footer
      >
        <template v-slot:header>
          <v-toolbar>
            <v-text-field
              v-model="search"
              clearable
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search"
            ></v-text-field>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-spacer></v-spacer>
              <v-select
                v-model="sortBy"
                flat
                solo-inverted
                hide-details
                :items="keys"
                prepend-inner-icon="mdi-magnify"
                label="Sort by"
              ></v-select>
              <v-spacer></v-spacer>
              <v-btn-toggle v-model="sortDesc" mandatory>
                <v-btn depressed :value="false">
                  <v-icon>mdi-sort-descending</v-icon>
                </v-btn>
                <v-btn depressed :value="true">
                  <v-icon>mdi-sort-ascending</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.publicId"
              cols="12"
              sm="6"
              md="4"
              lg="2"
            >
              <MaterialCard
                :material="item"
                :sortBy="sortBy"
                :readonly="readonly"
                :disabled="isDisabled(item) && !forceEnabled"
                :checked="checked.some(e => e === item.publicId)"
                @changeChecked="changeChecked"
                @disabledAction="disabledAction"
                @deleteMaterial="deleteMaterial"
                @updateMaterial="updateMaterial"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-row class="mt-1" align="center" justify="center">
            <span>Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn text class="ml-1" v-on="on">
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="checkAllMaterials" v-on="on">
                  <v-icon> {{ checkButtonIcon }} </v-icon>
                </v-btn>
              </template>
              <span> {{ checkButtonText }} </span>
            </v-tooltip>

            <v-spacer></v-spacer>

            <span class="mr-4">
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn fab small class="mr-1" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab small class="ml-1 mr-2" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </v-card>
</template>

<script>
import MaterialCard from './MaterialCard';

export default {
  name: 'MaterialIterator',
  props: {
    materials: { type: Array, required: true },
    species: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    checked: { type: Array, required: true },
    forceEnabled: { type: Boolean, default: false },
  },
  components: { MaterialCard },
  data() {
    return {
      itemsPerPageArray: [6, 12, 24],
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 6,
      sortBy: 'name',
      keys: ['Name', 'Formula', 'CAS', 'Phase', 'Molar Mass'],
      isMounted: false,
    };
  },
  methods: {
    deleteMaterial(publicId) {
      this.$emit('deleteMaterial', publicId);
    },
    updateMaterial(updatedMaterial) {
      this.$emit('updateMaterial', updatedMaterial);
    },
    changeChecked(publicId) {
      this.$emit('changeChecked', publicId);
    },
    isDisabled(item) {
      var isDisabled =
        this.species.some(s => s.publicId === item.publicId) ||
        item.rho === 0.0 ||
        item.rho === null;
      return isDisabled;
    },
    disabledText(item) {
      if (this.species.some(s => s.publicId === item.publicId)) {
        return 'Species already in mixture';
      } else if (
        item.rho <= 0.0 ||
        item.rho === undefined ||
        item.rho === null
      ) {
        return 'No Density defined for material. Define it first!';
      }
    },
    disabledAction(item) {
      this.$emit('disabledAction', this.disabledText(item));
    },
    checkAllMaterials() {
      this.$emit('checkAllMaterials');
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateCurrentPage() {
      this.page = Math.min(this.page, this.numberOfPages);
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.numberOfItems / this.itemsPerPage);
      // return Math.ceil(this.materials.length / this.itemsPerPage);
    },
    numberOfItems() {
      return this.isMounted
        ? this.$refs.materialIterator.$children[0].filteredItems
            .length
        : this.materials.length;
    },
    filteredKeys() {
      return this.keys.filter(key => key !== `Name`);
    },
    checkButtonText() {
      return this.checked.length > 0
        ? 'Deselect All Materials'
        : 'Select All Materials';
    },
    checkButtonIcon() {
      return this.checked.length > 0
        ? 'mdi-checkbox-marked'
        : 'mdi-checkbox-blank-outline';
    },
  },
  mounted() {
    this.isMounted = true;
  },
  watch: {
    numberOfPages() {
      this.updateCurrentPage();
    },
  },
};
</script>

<style></style>
